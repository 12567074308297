import React from 'react';
import logo from './images/logRound.svg';
import pdlogo from './pdgs.jpg';
import './App.css';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';


import instIcon from './images/instIcon.svg';
import googleIcon from './images/googleIcon.svg';
import webIcon from './images/webIcon.svg';

import grad from './images/grad.svg';
import card from './images/card.svg';

import sk from './images/skills.svg';

import top from './images/top.svg';

import bookmarkSymbol from './images/bookmarkSymbol.svg';
import bulbSymbol from './images/bulbSymbol.svg';
import cupSymbol from './images/cupSymbol.svg';
import micSymbol from './images/micSymbol.svg';

import worksBack from './images/worksBack.svg';

function App() {



  /* function test() {

    let counter = 120;

    counter = false;

    counter = "foo";

  } */

  return (
    <div className="App">
      <header className="App-header">

          <div style={{
            width: "100%",
            maxHeight: 113,
            height: "10vh",
            minHeight: 90,
            backgroundColor: "#1A0B2E",
            display: "flex",
            
          }}>
            <a href="www.shahabebrahimi.ir"> 
              <img src={logo} /* className="App-logo" */ alt="logo"

              style={{
                width: "100px",
                height: "90%",
                //backgroundColor: "gray",
                borderRadius: "150px",
                marginTop: "7px",
                marginLeft: "27vw",
                marginRight: "30vw",
                cursor: "pointer",
              }}
              />
            </a>
            

                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "-5vw",
                  marginTop: "2vh",
                  
                }}>
                  <a style={{
                    textDecoration: "none",
                    color: "white"
                    }} href="www.shahabebrahimi.ir"> 
                  <p style={{
                    fontSize: "20px",
                    marginRight: 100,
                    cursor: "pointer",
                    
                  }}>
                  Home
                  </p>
                  </a>
                  <a style={{
                    textDecoration: "none",
                    color: "white"
                    }} href="#Portfolio"> 
                  <p  style={{
                    fontSize: "20px",
                    marginRight: 100,
                    cursor: "pointer",
                  }}>
                  Portfolio
                  </p>
                  </a>
                  <a style={{
                    textDecoration: "none",
                    color: "white"
                    }} href="#About"> 
                  <p  style={{
                    fontSize: "20px",
                    marginRight: 100,
                    cursor: "pointer",
                  }}>
                  About
                  </p>
                  </a>
                </div>
          </div>

          <div >
            <img src={top}
            style={{
              width: "50vw",
              minWidth: 600,
              maxWidth: 900,
              marginTop: 80,
              marginBottom: 150,

            }}>

            </img>
          </div>

          <div>

          <img src={worksBack}
            style={{
              /* width: "30vw",
              minWidth: 600,
              maxWidth: 900,
              marginTop: 80,
              marginBottom: 150, */
              marginBottom: -620,
            }}>

            </img>

            <p style={{
              fontSize: 40,
              fontWeight: "100",
              marginBottom: 15,
              //marginLeft: "-20vw"
            }}>Work Experience</p>

            <div style={{
              display: "flex"
            }}>
              
              <div style={{
                maxWidth: 570,
                width: "30vw",
                height: 192,
                margin: 10,
                backgroundColor: "#38126D",
                borderRadius: 15
              }}>

              <div style={{
                maxWidth: 570,
                width: "30vw",
                height: 192,
                marginTop: 3,
                backgroundColor: "#261045",
                borderRadius: 15
              }}>

                
                <div style={{
                  height: 1
                }}>
                  </div>
                <p style={{
                      marginLeft: 50,
                      marginTop: 25,
                      fontWeight: "200"
                    }}>Web & App Developer</p>

                <div style={{
                  display: "flex"
                }}>

                  <img 
                    src={bookmarkSymbol}
                    style={{
                      marginLeft: 20,
                      marginTop: -40,
                      width: 120
                    }}
                    >

                  </img>

                  <div>

                    <p align="justify" style={{
                      fontSize: 12,
                      fontWeight: "900",
                      width: 200,
                      justifyContent: "left",
                      marginTop: -20,
                      marginLeft:20,

                    }}> Design and Development most advanced technology web apps and mobile apps
                    </p>


                    <div style={{
                      width: 120,
                      height: 33,
                      marginLeft: 20,
                      marginTop: 0,
                      backgroundColor: "#2C1250",
                      borderColor: "#693B93",
                      borderWidth: 2,
                      border: "1px solid #693B93",
                      borderRadius: 10,
                      
                    }}>

                      <p  style={{
                        fontSize: 14,
                        marginTop: 6,
                        
                      }}>
                        LEARN MORE
                      </p>

                    </div>

                  </div>

                </div>

              </div>

              </div>

              <div style={{
                maxWidth: 570,
                width: "30vw",
                height: 192,
                margin: 10,
                backgroundColor: "#38126D",
                borderRadius: 15
              }}>

              <div style={{
                maxWidth: 570,
                width: "30vw",
                height: 192,
                marginTop: 3,
                backgroundColor: "#261045",
                borderRadius: 15
              }}>

                
                <div style={{
                  height: 1
                }}>
                  </div>
                <p style={{
                      marginLeft: 50,
                      marginTop: 25,
                      fontWeight: "200"
                    }}>Distributed Sys Expert</p>

                <div style={{
                  display: "flex"
                }}>

                  <img 
                    src={bulbSymbol}
                    style={{
                      marginLeft: 20,
                      marginTop: -30,
                      width: 120
                    }}
                    >

                  </img>

                  <div>

                    <p align="justify" style={{
                      fontSize: 12,
                      fontWeight: "900",
                      width: 200,
                      justifyContent: "left",
                      marginTop: -20,
                      marginLeft:20,

                    }}> Design and Development most advanced technology web apps and mobile apps
                    </p>


                    <div style={{
                      width: 120,
                      height: 33,
                      marginLeft: 20,
                      marginTop: 0,
                      backgroundColor: "#2C1250",
                      borderColor: "#693B93",
                      borderWidth: 2,
                      border: "1px solid #693B93",
                      borderRadius: 10,
                      
                    }}>

                      <p  style={{
                        fontSize: 14,
                        marginTop: 6,
                        
                      }}>
                        LEARN MORE
                      </p>

                    </div>

                  </div>

                </div>

              </div>

              </div>

              
            </div>

            <div style={{
              display: "flex"
            }}>
              
              <div style={{
                maxWidth: 570,
                width: "30vw",
                height: 192,
                margin: 10,
                backgroundColor: "#38126D",
                borderRadius: 15
              }}>

              <div style={{
                maxWidth: 570,
                width: "30vw",
                height: 192,
                marginTop: 3,
                backgroundColor: "#261045",
                borderRadius: 15
              }}>

                
                <div style={{
                  height: 1
                }}>
                  </div>
                <p style={{
                      marginLeft: 35,
                      marginTop: 25,
                      fontWeight: "200"
                    }}>Networks Administrator</p>

                <div style={{
                  display: "flex"
                }}>

                  <img 
                    src={cupSymbol}
                    style={{
                      marginLeft: 20,
                      marginTop: -35,
                      width: 120
                    }}
                    >

                  </img>

                  <div>

                    <p align="justify" style={{
                      fontSize: 12,
                      fontWeight: "900",
                      width: 200,
                      justifyContent: "left",
                      marginTop: -20,
                      marginLeft:20,

                    }}> Design and Development most advanced technology web apps and mobile apps
                    </p>


                    <div style={{
                      width: 120,
                      height: 33,
                      marginLeft: 20,
                      marginTop: 0,
                      backgroundColor: "#2C1250",
                      borderColor: "#693B93",
                      borderWidth: 2,
                      border: "1px solid #693B93",
                      borderRadius: 10,
                      
                    }}>

                      <p  style={{
                        fontSize: 14,
                        marginTop: 6,
                        
                      }}>
                        LEARN MORE
                      </p>

                    </div>

                  </div>

                </div>

              </div>

              </div>

              <div style={{
                maxWidth: 570,
                width: "30vw",
                height: 192,
                margin: 10,
                backgroundColor: "#38126D",
                borderRadius: 15
              }}>

              <div style={{
                maxWidth: 570,
                width: "30vw",
                height: 192,
                marginTop: 3,
                backgroundColor: "#261045",
                borderRadius: 15
              }}>

                
                <div style={{
                  height: 1
                }}>
                  </div>
                <p style={{
                      marginLeft: 130,
                      marginTop: 25,
                      fontWeight: "200"
                    }}>Content Creator</p>

                <div style={{
                  display: "flex"
                }}>

                  <img 
                    src={micSymbol}
                    style={{
                      marginLeft: 20,
                      marginTop: -40,
                      width: 120
                    }}
                    >

                  </img>

                  <div>

                    <p align="justify" style={{
                      fontSize: 12,
                      fontWeight: "900",
                      width: 200,
                      justifyContent: "left",
                      marginTop: -20,
                      marginLeft:20,

                    }}> Design and Development most advanced technology web apps and mobile apps
                    </p>


                    <div style={{
                      width: 120,
                      height: 33,
                      marginLeft: 20,
                      marginTop: 0,
                      backgroundColor: "#2C1250",
                      borderColor: "#693B93",
                      borderWidth: 2,
                      border: "1px solid #693B93",
                      borderRadius: 10,
                      
                    }}>

                      <p  style={{
                        fontSize: 14,
                        marginTop: 6,
                        
                      }}>
                        LEARN MORE
                      </p>

                    </div>

                  </div>

                </div>

              </div>

              </div>

              
            </div>

            

            

          </div>

          <img style={{
                minWidth: 400,
                width: "50%",
                maxWidth: 895,
                marginTop: 200
                }} src={sk}>
            </img>

        <div
          className='Main-contain'
          /* style={{
            padding: "30px",
            borderWidth: 0.8,
            borderColor: "lightgrey",
            borderStyle: "solid",
            borderRadius: 50,
            margin: 80,
            width: "85vw"
          }} */
        >
          
          
            <div>
              <div style={{
                width: "50%",
                height: "200px",
                //backgroundColor: "red"
                //marginBottom: 40,
              }}>

          
          {/* <p style={{
            fontSize: "18px"
          }}> */}

            {/* <p style={{
              fontSize: "30px"
            }}>
            Welcome
            </p> */}

            {/* <br/> */}

                {/* <p style={{
                  fontSize: "24px"
                }}>
                Home
                </p> */}

                
                

                {/* <p style={{
                  fontSize: "24px"
                }}>
                About Us
                </p>

                <p style={{
                  fontSize: "24px"
                }}>
                Contact
                </p> */}

              </div>
              <div  id="Portfolio" /* style={{
                backgroundColor: "gray",
                padding: "10px",
                borderRadius: "25px",

                
              }} */>
              {/* <Slide
              
              >
                
                  <div className="each-slide-effect"
                  >
                    <iframe
                      style={{
                        height: "800px",
                        width: "90%",
                        minWidth: 350,
                        borderRadius: 35,
                      }}
                      id="jigarchiPreview"
                      title="jigarchi"
                      src="https://jigarchi.ae">
                    </iframe>
                  </div>
                  <div className="each-slide-effect">
                    <iframe
                      style={{
                        height: "800px",
                        width: "90%",
                        minWidth: 350,
                        borderRadius: 35,
                      }}
                      id="tworaPreview"
                      title="twora"
                      src="https://twora.netlify.app/">
                    </iframe>
                  </div>
                  <div className="each-slide-effect">
                    <iframe
                      style={{
                        height: "800px",
                        width: "90%",
                        minWidth: 350,
                        borderRadius: 35,
                      }}
                      id="tworaPreview"
                      title="twora"
                      src="https://baroque-nft.netlify.app/">
                    </iframe>
                  </div>
                  <div className="each-slide-effect">
                    <iframe
                      style={{
                        height: "800px",
                        width: 400,
                        borderRadius: 65,
                        //minWidth: 350
                      }}
                      id="cutePreview"
                      title="cute"
                      src="https://labelle.netlify.app/">
                    </iframe>
                  </div>
                
              </Slide> */}
              </div>
            </div>

            <p style={{
              fontSize: 40,
              fontWeight: "100",
              marginTop: -100,
            }}>Recent Projects</p>


            {
              ["https://cute-beauty-org.netlify.app/", "https://jigarchi.ae", "https://baroque-nft.netlify.app/", "https://twora.netlify.app/", ].map((data, key) => {

                {
                  return key == 0 || key == 2
                  ?

                  <div  style={{
                    display: "flex"
                  }}>
      
                  <img style={{
                      maxWidth: "572px",
                      width: "40%",
                      marginLeft: "40vw"
                      //height: 641,
                    }}
                    src={grad}>
      
                  </img>
                  <img style={{
                      maxWidth: "572px",
                      width: "40%",
                      marginLeft: -350
                      //height: 641,
                    }}
                    src={grad}>
      
                  </img>


                  <div style={{
                    marginTop: 250,
                    maxWidth: 669,
                    width: "50%",
                  }}>
                    
                    <p align= "justify" style={{
                    width: 800,
                    height: 120,
                    marginBottom: 0,
                    marginLeft: -1450,
                    fontSize: 18,
                    borderRadius: 14,
                    padding: 25,
                    paddingTop: 5,
                    color: "#CCD6F6",
                    backdropFilter: "blur(40px)",
                    background: "radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(118, 60, 172, 0.2) 0%, rgba(110, 191, 244, 0.0447917) 77.08%, rgba(70, 144, 213, 0) 100%)",
                    backgroundBlendMode: "overlay, normal",
                  }}>
  
                    {
                      key === 0
                        ?
                        <p> Cute Beauty is a PWA mobile app for beauty online shop. it is designed by Figma and Implemented by react pwa technology ( android and iOS supported ) <br/> فروشگاه لابل مناسب برای محصولات زیبایی، قابل نصب روی کلیه گوشی‌های هوشمند</p>
                        :
                        false
                    }
                    {
                      key === 1
                        ?
                        <p>Jigarchi is the first Charbroiled Liver Shop in Dubai, Enjoy your top quality Iranian kebab and liver in a very luxury environment <br/> جیگرچی اولین جیگرکی تخصصی در دوبی است ، وبسایت این فروشگاه شامل منو و اطلاعات فروشگاهی با استفاده از ریکت و مونگودیبی پیاده سازی شده است </p>
                        :
                        false
                    }
  
                    {
                      key === 2
                        ?
                        <p>Barouqe is a nft art meuseum and market, It is a mockup implemented in react using Figma design file. <br/> باروک یک موزه هنری آنلاین ان اف تی است. نمونه طرح موجود با استفاده از ریکت و از روی فایل طراحی فیگما پیاده سازی شده است </p>
                        :
                        false
                    }
  
                    {
                      key === 3
                        ?
                        <p>وبسایت تورا یک فروشگاه اینترنتی فروش محصولات دارویی دامی می‌باشد. این وبسایت با استفاده از ریکت و بوت استرپ پیاده سازی شده و از ویژگی‌های این فروشگاه می‌توان به امکان ایجاد حساب کاربری و پرداخت و خرید آنلاین اشاره نمود</p>
                        :
                        false
                    }
  
                    
                  
                  
                  </p>
      
  
  
                  
  
                  </div>

      
                  <div style={{
                    maxWidth: 582.88,
                    width: "40%",
                    height: 341,
                    borderRadius: 10,
                    backgroundColor: "#2B0B3A",
                    marginLeft: -1400,
                    marginTop: 150
                  }}>
                    
                    
                    <div style={{
                      maxWidth: 530,
                      width: "90%",
                      height: 300,
                      backgroundColor: "white",
                      marginLeft: 55,
                      marginTop: 40,
                      borderRadius: "50px 0px 10px 0px"
                    }}>
      
                    <iframe
                            style={{
                              height: 300,
                              //maxWidth: 530,
                              width: "100%",
                              borderRadius: "50px 0px 10px 0px"
                            }}
                            id="tworaPreview"
                            title="twora"
                            src= {data}>
                          </iframe>
      
                    </div>
      
                  </div>


                  
  
                  
      
                  {/* <p>
                    A web app for visualizing personalized Spotify data. View your
                    top artists, top tracks, recently played tracks, and detailed audio
                    information about each track. Create and save new playlists of
                    recommended tracks based on your existing playlists and more.
                    </p> */}
      
                  </div>

                  :

                  <div  style={{
                    display: "flex"
                  }}>
      
                  <img style={{
                      maxWidth: "572px",
                      width: "40%",
                      //height: 641,
                    }}
                    src={grad}>
      
                  </img>
      
                  <div style={{
                    maxWidth: 582.88,
                    width: "40%",
                    height: 341,
                    borderRadius: 10,
                    backgroundColor: "#2B0B3A",
                    marginLeft: -400,
                    marginTop: 150
                  }}>
                    
                    
                    <div style={{
                      maxWidth: 530,
                      width: "90%",
                      height: 300,
                      backgroundColor: "white",
                      marginLeft: 0,
                      marginTop: 40,
                      borderRadius: "0px 50px 0px 10px"
                    }}>
      
                    <iframe
                            style={{
                              height: 300,
                              //maxWidth: 530,
                              width: "100%",
                              borderRadius: "0px 50px 0px 10px"
                            }}
                            id="tworaPreview"
                            title="twora"
                            src= {data}>
                          </iframe>
      
                    </div>
      
                  </div>
  
                  <div style={{
                    marginTop: 250,
                    maxWidth: 669,
                    width: "50%",
                  }}>
                    
                    <p align= "justify" style={{
                    
                    height: 120,
                    marginBottom: 0,
                    marginLeft: -80,
                    fontSize: 18,
                    borderRadius: 14,
                    padding: 25,
                    paddingTop: 5,
                    color: "#CCD6F6",
                    backdropFilter: "blur(40px)",
                    background: "radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(118, 60, 172, 0.2) 0%, rgba(110, 191, 244, 0.0447917) 77.08%, rgba(70, 144, 213, 0) 100%)",
                    backgroundBlendMode: "overlay, normal",
                  }}>
  
                    {
                      key === 0
                        ?
                        <p> Cute Beauty is a PWA mobile app for beauty online shop. it is designed by Figma and Implemented by react pwa technology ( android and iOS supported ) <br/> فروشگاه لابل مناسب برای محصولات زیبایی، قابل نصب روی کلیه گوشی‌های هوشمند</p>
                        :
                        false
                    }
                    {
                      key === 1
                        ?
                        <p>Jigarchi is the first Charbroiled Liver Shop in Dubai, Enjoy your top quality Iranian kebab and liver in a very luxury environment <br/> جیگرچی اولین جیگرکی تخصصی در دوبی است ، وبسایت این فروشگاه شامل منو و اطلاعات فروشگاهی با استفاده از ریکت و مونگودیبی پیاده سازی شده است </p>
                        :
                        false
                    }
  
                    {
                      key === 2
                        ?
                        <p>Barouqe is a nft art meuseum and market, It is a mockup implemented in react using Figma design file. <br/> باروک یک موزه هنری آنلاین ان اف تی است. نمونه طرح موجود با استفاده از ریکت و از روی فایل طراحی فیگما پیاده سازی شده است </p>
                        :
                        false
                    }
  
                    {
                      key === 3
                        ?
                        <p>وبسایت تورا یک فروشگاه اینترنتی فروش محصولات دارویی دامی می‌باشد. این وبسایت با استفاده از ریکت و بوت استرپ پیاده سازی شده و از ویژگی‌های این فروشگاه می‌توان به امکان ایجاد حساب کاربری و پرداخت و خرید آنلاین اشاره نمود</p>
                        :
                        false
                    }
  
                    
                  
                  
                  </p>
      
  
  
                  
  
                  </div>
      
                  {/* <p>
                    A web app for visualizing personalized Spotify data. View your
                    top artists, top tracks, recently played tracks, and detailed audio
                    information about each track. Create and save new playlists of
                    recommended tracks based on your existing playlists and more.
                    </p> */}
      
                  </div>

                }
                

              })
            }

            
            
            <br/>
            <br/>

            <div id="About">

            </div>
            <p style={{
              fontSize: 25,
              textAlign: "left"
            }}>
              Contact
            </p>

            <p style={{
              fontSize: 15,
              //width: "80%"
              textAlign: "left"
            }}>
            I'm currently looking to join a cross-functional team that values improving people's lives
            through accessible design. or have a project in mind? Let's connect.
              <br/>
              <br/>
              Email: <code>shahab.ebrahimi@gmail.com</code>
              <br/>
              <br/>
              Phone and Whatsapp: <code>+98 937 580 2111</code>
              <br/>
              Telegram: @<code>sh7eb</code>

              
            </p>

            <a
            className="App-link"
            href="https://paranoid.ir"
            target="_blank"
            rel="noopener noreferrer"

            style={{
              fontSize: 15,
              //textAlign: "left"
              //width: "80%"
            }}
            >
              paranoid.ir
            </a>

            <br/>

            <img style={{margin: 10}} src={instIcon}>
            </img>

            <img style={{margin: 10}} src={webIcon}>
            </img>

            <img style={{margin: 10}} src={googleIcon}>
            </img>


        </div>    

        

      </header>

      
    </div>
  );
}

export default App;
